import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  signal,
  viewChild,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterEvent,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { Subscription } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { IContent, ISocialSection } from 'shared/models';
import { environment } from 'src/environments/environment';
import { CookieLawContainerComponent } from './shared/components/cookie-law/cookie-law-container.component';
import { CookieLawComponent } from './shared/components/cookie-law/cookie-law.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ScrollDoAnimationDirective } from './shared/directives/scroll-do-animation.directive';
import { ScrollToTopDirective } from './shared/directives/scrollToTop.directive';
import { ContentService } from './shared/services';
import { AnalyticsService } from './shared/services/analytics.service';
import { SharedService } from './shared/services/shared.service';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterOutlet,
    CookieLawContainerComponent,
    RouterLink,
    ShareButtons,
    ScrollToTopDirective,
    ScrollDoAnimationDirective,
  ],
})
export class AppComponent {
  social = signal<ISocialSection>(null);
  footerCallToAction = signal<IContent>(null);
  cookies = signal(false);
  expirationTime = 30;
  shareUrl = signal('');
  private shareIcon = viewChild<ElementRef>('shareIcon');
  private shareButtons = viewChild<ElementRef>('shareButtons');
  private cookieLawRef = viewChild<CookieLawComponent>('cookieLaw');
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    updates: SwUpdate,
    sharedService: SharedService,
    contentService: ContentService,
    router: Router,
    analyticService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscriptions.push(
      contentService
        .getSocial()
        .pipe(
          map((social) => {
            this.social.set(social);
            sharedService.setSocialSection(social);
          }),
        )
        .subscribe(),
    );

    this.subscriptions.push(
      sharedService
        .getFooterCallToAction()
        .pipe(
          tap((footer) => {
            this.footerCallToAction.set(footer);
          }),
        )
        .subscribe(),
    );
    if (isPlatformBrowser(platformId)) {
      this.cookies.set(true);
      this.checkUpdateAvailable(updates);
      this.addGoogleAnalyticsTag();

      this.subscriptions.push(
        router.events
          .pipe(
            filter((value: unknown) => value instanceof NavigationEnd),
            map((value: RouterEvent) => {
              this.shareUrl.set(
                `https://${window.location.hostname}${value.url}`,
              );
              const shareIconElement = this.shareIcon()?.nativeElement;
              const shareButtonElement = this.shareButtons()?.nativeElement;
              if (
                shareButtonElement &&
                shareIconElement &&
                shareIconElement.classList.contains('fa-times')
              ) {
                shareIconElement.classList.toggle('fa-times');
                shareIconElement.classList.toggle('fa-share-alt');
                shareButtonElement.classList.toggle('social--button');
                shareButtonElement.classList.remove('active_button');
                shareButtonElement.classList.toggle('soc_btn_up_down');
              }
            }),
          )
          .subscribe(),
      );

      analyticService.logEvents('page_view');
      const media = document.querySelectorAll('.sb-wrapper');
      media.forEach((item) => {
        item.addEventListener('click', (event) => {
          const shareSocial = (event.currentTarget as HTMLInputElement)
            .getAttribute('aria-label')
            .replace('Share on ', '');

          analyticService.logEvents('share_button_clicked', {
            share_button_platform: shareSocial,
          });
        });
      });
    }
  }

  private addGoogleAnalyticsTag() {
    if (environment.gtagUrl !== '') {
      const script = this.document.createElement('script');
      script.async = true;
      script.src = environment.gtagUrl;
      this.document.head.prepend(script);
    }
  }

  private checkUpdateAvailable(updates: SwUpdate) {
    if (updates.isEnabled) {
      this.subscriptions.push(
        updates.versionUpdates
          .pipe(
            filter((event) => event.type === 'VERSION_READY'),
            map(() => {
              confirm(
                `We updated our site content, please press ok to read new content`,
              );
              window.location.reload();
            }),
            catchError((error: Error) => {
              console.log('ERROR', error);
              throw error;
            }),
          )
          .subscribe(),
      );
    }
  }

  cookiesAccepted() {
    this.cookieLawRef()?.dismiss();
  }

  onClickShare() {
    const shareIconElement = this.shareIcon()?.nativeElement;
    const shareButtonElement = this.shareButtons()?.nativeElement;
    if (shareButtonElement && shareIconElement) {
      shareIconElement.classList.toggle('fa-times');
      shareIconElement.classList.toggle('fa-share-alt');
      shareButtonElement.classList.toggle('social--button');
      shareButtonElement.classList.toggle('active_button');
      shareButtonElement.classList.toggle('soc_btn_up_down');
    }
  }
}
